import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../Shared/Loader';


const UpdateModal = ({ updatePayment, setUpdatePayment,fetchData }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { _id, date, code, name, invoice, amount, purpose, status } = updatePayment;
    const [isSupplierSearching, setIsSupplierSearching] = useState(false);
    const [supplierText, setSupplierText] = useState([]);
    const [supplierSearch, setSupplierSearch] = useState([]);
    const [currentSupplier, setCurrentSupplier] = useState([]);
    const [isSelectSupplier, setIsSelectSupplier] = useState(false);

    useEffect(() => {
        fetch('https://16ana.net/ayc/getsuppliers')
            .then(res => res.json())
            .then(data => {
                setSuppliers(data);
                setLoading(false)
            })
    }, [])

    const onSubmit = (data) => {
        const name=currentSupplier?.name;
        const code=currentSupplier?.code;
        const payment = parseInt(data.amount);
        const user = userAc?.email.split('@')[0];
        const approvedBy = false;
        const newData = { ...data,name,code, payment, user, approvedBy }
        fetch(`https://16ana.net/ayc/getpayment/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => {
                setUpdatePayment(null);
                toast('Payment Upadated Successful');
                fetchData();
            })
    }

    const noEdit = () => {
        window.location.reload(false);
    }

    if (loading) {
        return <Loader />
    }

    const handleSearchSupplier = (event) => {
        setSupplierText(event.target.value)
        const match = suppliers.filter(supplier => supplier.name.toLowerCase().includes(supplierText)
            || supplier.name.includes(supplierText) || supplier.name.toUpperCase().includes(supplierText));
        setSupplierSearch(match);
        setIsSupplierSearching(true);
    }

    const handleSelectSupplier = (supplier, event) => {
        event.preventDefault();
        event.stopPropagation();
        const opening = parseFloat(supplier?.opening)
        const url = `https://16ana.net/ayc/filteredsupplier?code=${supplier.code}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const due = opening + data?.defaultTotalDue - data?.defaultTotalCollect;
                setCurrentSupplier({ ...supplier, due });
                setIsSelectSupplier(true)
            })
        setIsSupplierSearching(false);
        setSupplierText('');
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal mt-12">
                <div class="modal-box relative text-xs">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg font-bold">Update Payment of-{code}. {name}</h3>
                    <div class="form-control w-full max-w-xs mt-5 col-span-2 mx-auto">
                        <div class="form-control w-full">
                            <input onChange={handleSearchSupplier} value={supplierText} type="text" placeholder="Search Supplier" class="rounded-lg bg-red-300 p-3 placeholder-black  text-center  focus:border-blue-500 focus:outline-none w-full max-w-xs" />
                        </div>
                        <div style={isSupplierSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSupplierSearching ? 'h-16' : "h-8"} w-full overflow-auto`}>
                            {
                                isSupplierSearching ? supplierSearch.map(supplier =>
                                    <label for="update-modal" onClick={event => handleSelectSupplier(supplier, event)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{supplier.code}. {supplier?.name}</label>) :
                                    suppliers.map(supplier =>
                                        <label for="update-modal" onClick={event => handleSelectSupplier(supplier, event)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{supplier.code}. {supplier?.name}</label>)
                            }
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 gap-4 justify-items-center'>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Select Date</span>
                            </label>
                            <input {...register("date")} type="date" defaultValue={date} placeholder="Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Supplier Name</span>
                            </label>
                            <input  {...register("name")} type="text" value={!isSelectSupplier ? name : currentSupplier?.name || ''} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Supplier Code</span>
                            </label>
                            <input  {...register("code")} type="text" value={!isSelectSupplier ? code : currentSupplier?.code || ''} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Invoice No</span>
                            </label>
                            <input {...register("invoice")} type="text" defaultValue={invoice} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Amount</span>
                            </label>
                            <input {...register("amount")} type="number" defaultValue={amount} placeholder="Type Amount Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Purpose</span>
                            </label>
                            <input {...register("purpose")} type="text" defaultValue={purpose} placeholder="Type Payment Purpose Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option >Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Payment' className='btn bg-teal-600 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;