import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editBrand } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';



const MessageHistory = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [smsHistories, setSmsHistories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://16ana.net/ayc/getsmshistories')
            .then(res => res.json())
            .then(data => {
                setSmsHistories(data);
                setLoading(false)
            })
    }, [smsHistories, setSmsHistories])

    if (loading) {
        return <Loader />
    }

    const handleDeleteSMSHistory = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://16ana.net/ayc/getsmshistories/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                })
        }
    };


    const handleApprove = (brand) => {
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://16ana.net/ayc/getbrands/${brand._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ approvedBy })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Brand Approved');
                // window.location.reload(false)
            })
    }

    return (
        <div className='shadow-2xl px-2 pb-5 my-5 className="mt-10" text-xs'>
            {
                smsHistories.length ? <div class="overflow-x-auto mt-8">
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-teal-600 text-white normal-case'>SL</th>
                                <th className='bg-teal-600 text-white normal-case'>Donor Name</th>
                                <th className='bg-teal-600 text-white normal-case'>Donor Phone</th>
                                <th className='bg-teal-600 text-white normal-case'>Month</th>
                                <th className='bg-teal-600 text-white normal-case'>SMS</th>
                                <th className='bg-teal-600 text-white normal-case'>Delete</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                smsHistories.map((smsHistory, index) => <tr className='hover'>
                                    <td>{index + 1}</td>
                                    <td>{smsHistory.name}</td>
                                    <td>{smsHistory.number}</td>
                                    <td>{smsHistory?.month ? smsHistory.month : "General"}</td>
                                    <td> <textarea>{smsHistory?.msg}</textarea> </td>
                                    {userAc?.deleteBrand && <td><button onClick={() => handleDeleteSMSHistory(smsHistory._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div> : ""
            }
        </div>
    );
};

export default MessageHistory;