import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectPosCustomer } from '../../../../features/SalesSlice/SalesSlice';

const CustomerList = ({ customers }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showCustomerList, setShowCustomerList] = useState(false);
  const dispatch=useDispatch();

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term === '') {
      setShowCustomerList(false); // Hide the customer list when no search term is entered
      setFilteredCustomers([]); // Clear the filtered results
    } else {
      const filtered = customers.filter((customer) =>
        customer.name.toLowerCase().includes(term.toLowerCase())
      );

      setFilteredCustomers(filtered);
      setShowCustomerList(true); // Show the customer list when there is a search term
    }
  };

  const handleSelectCustomer=(cus)=>{
    const opening = parseFloat(cus?.opening)
        const url = `https://16ana.net/ayc/filteredmaincustomer?code=${cus?.code}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                const due = opening + data?.defaultTotalDue - data?.defaultTotalCollect;
                dispatch(selectPosCustomer({ ...cus, due }))
            })
    setShowCustomerList(false);
    setSearchTerm('')
  }

  return (
    <div className='text-center text-xs mt-2'>
      <input
        type="text"
        placeholder="Search customers..."
        className="w-60 p-2 border rounded-lg "
        value={searchTerm}
        onChange={handleSearch}
      />

      {showCustomerList && ( // Conditional rendering to show/hide customer list
        <ul className="mt-4 absolute bg-white border h-40 w-60 overflow-auto rounded-lg p-2 z-20">
          {filteredCustomers.map((customer) => (
            <li onClick={()=>handleSelectCustomer(customer)} key={customer.id} className="mb-2 text-xs bg-teal-600 text-white font-bold p-3 cursor-pointer
            hover:bg-white hover:text-teal-600 hover:border hover:border-1 hover:border-teal-600 hover:transition-transform">
              {customer.name} - {customer.email}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomerList;
