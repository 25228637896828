import React, { useEffect, useState } from 'react';
import BrandField from './BrandField';


const AddBrand = () => {
     return (
        <div className='mx-auto w-full p-2 mt-8'>
            <h1 className='text-md text-center bg-teal-600 p-3 text-white rounded-xl'>Add A New Brand Name</h1>
            <BrandField></BrandField>
        </div>
    );
};

export default AddBrand;