import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { editSupplier } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';
import usePaginationSearch from '../../../hooks/usePaginationSearch';
import ReactPaginate from 'react-paginate';
import { format } from 'date-fns';
import useFetchData from '../../../hooks/useFetchData';



const CustomerField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { items, pageCount, limit, pageNo, loading, handleSearchResult,
        handleLimit, handlePageClick, totalItem, refetchData } = usePaginationSearch('https://16ana.net/ayc/getmaincustomerswithfilter')
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [updateCustomer, setUpdateCustomer] = useState(null);
    const dispatch = useDispatch();
    const [isExpend, setIsExpend] = useState(false);
    const customerNoRef = useRef('');
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");
    const { items: sms } = useFetchData('https://16ana.net/ayc/getsms');
    const { items: customers } = useFetchData('https://16ana.net/ayc/getmaincustomers');
    const [customerNo, setCustomerNo] = useState('');
    const [dupplicateDonor, setDupplicateDonor] = useState(false);
    const [isChecked, setChecked] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    // const customerCode = (totalItem + 1).toString().padStart(5, '0');

    useEffect(() => {
        const myLength = (customers.length + 1).toString().padStart(5, '0');
        const isExist = items?.filter(cus => cus?.code === myLength);
        const duplicate = isExist?.length ? true : false;

        if (duplicate) {
            // Increment the suffix to avoid duplicates
            const suffix = isExist.length + 1;
            setCustomerNo(`${myLength}-${suffix}`);
            setIsLoading(false)
        } else {
            setCustomerNo(myLength);
            setIsLoading(false)
        }
    }, [items]);

    if (loading) {
        return <Loader />
    }
    if (isLoading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0];
        const code = customerNoRef?.current?.value;
        // Input date string
        // const inputDateString = data.firstDate;
        const inputDate = new Date(data?.firstDate);
        const firstMonth = inputDate.getFullYear();
        const donarType = data?.monthly === "0" ? 'Regular' : 'Monthly'



        // console.log(code);
        fetch('https://16ana.net/ayc/addmaincustomer', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ user, code, firstMonth, ...data, donarType }),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Donar Added')
                reset();
                refetchData();
                setIsExpend(false);
                setTimeout(() => { window.location.reload(); }, 3000);
            })



        if (sms[0]?.smsAmount > 3 & isChecked) {
            const token = `92310756091678413369ac9dc34ff7599524a6ea84a77094beb1`;
            const number = data.phone;
            const msg = `প্রিয় ${data.name}, আসসালামু আলাইকুম, একতা যুব সমাজের একজন দাতা সদস্য হওয়ার জন্য আপনাকে ধন্যবাদ। প্রয়োজনে 01971909352`;
            // বরাবরের নেয় এ বছরও আগামি ২৭শে ফেব্রুয়ারি ২০২৪ইং তারিখে আমাদের ইসলামি মহাসম্মেলন অনুষ্ঠিত হতে যাচ্ছে ইনশাআল্লাহ। আপনার একান্ত সহযোগিতা কামনা করছি।
            // const msg = `প্রিয় ${data.name}, আসসালামু আলাইকুম,একতা যুব সমাজের সম্মানিত সদস্য হিসেবে আপনি জেনে অত্যন্ত আনন্দিত হবেন যে, প্রতি বছরের ন্যায় একতা যুব সমাজের উদ্যোগে এ বছরও আগামি ২৭শে ফেব্রুয়ারি ২০২৪ইং তারিখে ইসলামি মহাসম্মেলন অনুষ্ঠিত হতে যাচ্ছে ইনশাআল্লাহ। উক্ত মহাসম্মেলনকে সফলভাবে বাস্তবায়নের লক্ষ্যে আগামিকাল শুক্রবার সন্ধ্যা সাড়ে ৭টায় ফকিরবাড়ি জামে মসজিদে অত্যন্ত গুরুত্বপূর্ণ পরামর্শসভা অনুষ্ঠিত হবে ইনশাআল্লাহ। তাই মাহফিল কামিয়াবির লক্ষ্যে আগামিকালের পরামর্শে আপনাকে উপস্থিত হওয়ার জন্য অনুরুধ করা হলো। প্রয়োজনে-01971909352`;
            // const msg = `প্রিয় ${data.name}, আসসালামু আলাইকুম একতা যুব সমাজের সম্মানিত সদস্য। আজ রাত ৭ঃ৩০ মিনিটে কাওরাইদ মধ্যপাড়া ফকির বাড়ি জামে মসজিদে মাহফিলের পরামর্শে আপনার উপস্থিতি কামনা করছি,01971909352`;
            const message = encodeURI(msg)
            const url = `https://api.greenweb.com.bd/api.php?token=${token}&to=${number}&message=${message}`

            fetch(url)
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })

            fetch(`https://16ana.net/ayc/getsms/${sms[0]?._id}`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ smsAmount: sms[0]?.smsAmount - 2 })
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })

            fetch(`https://16ana.net/ayc/smshistory`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ name: data.name, number: data.phone, msg })
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    toast.success(`SMS পাঠানো হয়েছে`)
                })
        }

    }

    const handleDeleteCustomer = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://16ana.net/ayc/getmaincustomer/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateCustomer(null);
                    refetchData(pageNo)
                    setTimeout(() => { window.location.reload(); }, 3000);
                })
        }
    };

    const handleUpdateCustomer = (customer) => {

        // fetch(`https://16ana.net/ayc/getsuppliers/${Supplier._id}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         dispatch(editSupplier(data))
        //     })
        setUpdateCustomer(customer);
    }

    const handleApprove = (customer) => {
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://16ana.net/ayc/getmaincustomer/${customer._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ approvedBy })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Donar Approved');
                refetchData(pageNo)
            })
    }

    const handleDupplicateDonar = (event) => {
        const phone = event.target.value;
        const filter = customers.find(cus => cus.phone === phone);
        if (filter) {
            setDupplicateDonor(true)
        } else {
            setDupplicateDonor(false)
        }

    }

    return (
        <div>
            <div className={`${isExpend ? 'shadow-2xl' : ''} px-12 pb-5 mt-5 text-xs`}>
                <div className='text-right'><button onClick={() => setIsExpend(!isExpend)} className='btn btn-sm btn-success text-white'>{isExpend === true ? <AiOutlineMinus className='text-lg font-bold' /> : <AiOutlinePlus className='text-lg font-bold' />}Add Donar</button>
                    {isExpend && <span className="flex items-center text-lg">
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={isChecked}
                            onChange={() => setChecked(!isChecked)}
                        />
                        Send SMS
                    </span>}
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={`container grid grid-cols-1 lg:grid-cols-3 gap-1 justify-center items-center ${isExpend === true ? "" : "hidden"}`}>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Donar Code</span>
                        </label>
                        <input ref={customerNoRef} value={customerNo} type="text" placeholder="Type Donar Code Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Donar Name</span>
                        </label>
                        <input {...register("name")} type="text" placeholder="Type Donar Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Address</span>
                        </label>
                        <input {...register("address")} type="text" placeholder="Type Address Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Phone No</span>
                        </label>
                        <input {...register("phone")} onChange={handleDupplicateDonar} type="text" placeholder="Type Phone No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Yearly Donation Amount</span>
                        </label>
                        <input {...register("monthly")} type="text" placeholder="Type Yearly Amount" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">First Donation Year</span>
                        </label>
                        {/* <input ref={dateRef} defaultValue={today} type="date" className='rounded-lg bg-gray-200 p-3  text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /> */}
                        <input {...register("firstDate")} defaultValue={today} type="date" placeholder="First Donation Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>

                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Status</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option selected>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div>
                    <input disabled={!userAc?.addCustomer | dupplicateDonor} type="submit" value='Add Donar' className='btn w-full lg:w-60 bg-teal-600 text-white mt-2 lg:mt-12' />
                </form>
            </div>
            <div className='text-xs mx-2 shadow-2xl p-2 lg:p-5'>
                <div className={`${isExpend === false ? "" : "hidden"}`}>
                    <div className='flex justify-between items-center my-4'>
                        <div class="form-control w-28  max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">Show</span>
                            </label>
                            <select onChange={handleLimit} defaultValue='' class="text-center text-xs rounded-lg text-black select select-bordered select-error w-full max-w-xs" required>
                                <option selected >20</option>
                                <option>10</option>
                                <option>50</option>
                                <option>100</option>
                                <option>All</option>
                            </select>
                        </div>
                        <div class="form-control w-60 mt-9 ml-2 lg:ml-0">
                            <input onChange={handleSearchResult} type="text" placeholder="Search Donar" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                        </div>
                    </div>
                    {
                        items.length ? <div class="overflow-x-auto mt-8">
                            <table class="table w-full mx-auto">
                                <thead className='text-center'>
                                    <tr>
                                        <th className='bg-teal-600 text-white normal-case'>SL</th>
                                        <th className='bg-teal-600 text-white normal-case'>Donar Code</th>
                                        <th className='bg-teal-600 text-white normal-case'>Donar Name</th>
                                        <th className='bg-teal-600 text-white normal-case'>Address</th>
                                        <th className='bg-teal-600 text-white normal-case'>Phone</th>
                                        <th className='bg-teal-600 text-white normal-case'>Yearly Amount</th>
                                        <th className='bg-teal-600 text-white normal-case'>First Year</th>
                                        <th className='bg-teal-600 text-white normal-case'>Status</th>
                                        <th className='bg-teal-600 text-white normal-case'>Added By</th>
                                        <th className='bg-teal-600 text-white normal-case'>Approved By</th>
                                        <th className='bg-teal-600 text-white normal-case'>Edit</th>
                                        <th className='bg-teal-600 text-white normal-case'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {
                                        items.map((customer, index) => <tr className='hover'>
                                            <td>{limit === "All" ? index + 1 : index + 1 + limit * (pageNo - 1)}</td>
                                            <td>{customer.code}</td>
                                            <td>{customer.name}</td>
                                            <td>{customer.address}</td>
                                            <td>{customer.phone}</td>
                                            <td>{customer.monthly}</td>
                                            <td>{customer.firstMonth}</td>
                                            <td> <button className={`btn btn-xs normal-case ${customer.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{customer?.status}</button> </td>
                                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{customer?.user ? customer.user : "User"}</button></td>
                                            {userAc.approvalCustomer ? <td>{customer?.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{customer?.approvedBy}</button> : <button onClick={() => handleApprove(customer)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td> :
                                                <td>{customer.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{customer.approvedBy}</button> : <button className='btn btn-xs btn-success text-white'>Not Approved</button>}</td>}
                                            {userAc?.editCustomer && <td><button><label for="update-modal" onClick={() => handleUpdateCustomer(customer)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                            {userAc?.deleteCustomer && <td><button onClick={() => handleDeleteCustomer(customer._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div> : ""
                    }
                    {limit === "All" ? "" : <div className='my-8 text-xs'>
                        <ReactPaginate
                            forcePage={pageNo - 1}
                            previousLabel={'Pre'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={'btn-group justify-center'}
                            pageClassName={'btn btn-success text-white'}
                            activeClassName={'btn bg-white text-black'}
                            previousClassName={'btn btn-success text-white'}
                            nextClassName={'btn btn-success text-white'}
                            breakClassName={'btn btn-success text-white'}></ReactPaginate>
                    </div>}
                </div>
            </div>
            {
                updateCustomer && <UpdateModal refetchData={refetchData} pageNo={pageNo} setUpdateCustomer={setUpdateCustomer} updateCustomer={updateCustomer}></UpdateModal>
            }

        </div>
    );
};

export default CustomerField;