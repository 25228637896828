import React from 'react';
import loading3 from "../../assets/images/loading3.gif"

const Loader = () => {
    return (
        <div className='h-screen flex justify-center items-center'>
           <div className="w-16 h-16 border-b-2 border-teal-600 rounded-full animate-spin"></div>
        </div>
    );
};

export default Loader;