import { render } from '@testing-library/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearEdit } from '../../../../features/InfoSlice/infoSlice';


const UpdateModal = ({updateOpeningCapital,setUpdateOpeningCapital }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    // const brand = useSelector((state) => state.info.brand[0]);
    const _id = updateOpeningCapital?._id;
    const name = updateOpeningCapital?.name;
    const opening = updateOpeningCapital?.opening;
    const status = updateOpeningCapital?.status;

    const onSubmit = (data) => {
        const user = userAc?.email.split('@')[0];
        const approvedBy = false;
        fetch(`https://16ana.net/ayc/getopeningcapital/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({user,...data,approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setUpdateOpeningCapital(null);
                toast('Opening Capital Upadated Successful');
                
            })
    }

    const noEdit = () => {
        window.location.reload(false)
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal">
                <div class="modal-box relative">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg font-bold">Update Opening Capital</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 gap-4 justify-items-center'>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text">Opening Capital Name</span>
                            </label>
                            <input {...register("name")} defaultValue={name} type="text" placeholder="Type Opening Capital Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text">Opening Balance</span>
                            </label>
                            <input {...register("opening")} defaultValue={opening} type="text" placeholder="Type Opening Balance Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option >Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Opening Capital' className='btn bg-teal-600 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;