import logo from '../../assets/images/logo.jpeg'

const BusinessInfo = {
    name: "Akota Jubo Shomaj",
    fullName: "Akota Jubo Shomaj",
    dealer: "()",
    address: "Kawraid,Sreepur, Gazipur",
    mobile: "Mobile: 01971909352",
    img: logo,
}

export default BusinessInfo;
