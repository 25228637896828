import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import Loader from '../../Shared/Loader';
import ReactPaginate from 'react-paginate';
import usePaginationSearch from '../../../hooks/usePaginationSearch';
import useFetchData from '../../../hooks/useFetchData';
import { usePostProductMutation } from '../../../features/productApi/productApi';



const ProductField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { items: items2 } = useFetchData('https://16ana.net/ayc/getproductscode');
    const [pdCode, setPdCode] = useState('')
    const { items, pageCount, limit, pageNo, loading, handleSearchResult,
        handleLimit, handlePageClick,refetchData } = usePaginationSearch('https://16ana.net/ayc/getproductswithfilter')
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [updateProduct, setUpdateProduct] = useState(null);
    const [loadingData, setLoadingData] = useState(true);
    const [isExpend, setIsExpend] = useState(false);

    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [units, setUnits] = useState([]);
    const [origins, setOrigins] = useState([]);



    const isExist = items2?.filter(pcode => pcode?.code === pdCode);
    const duplicate = isExist?.length ? true : false;
    const [key, setKey] = useState(0);

    const [postProduct, { isError, isSuccess }]=usePostProductMutation();

    // Output: "Hello,thisisasamplestring."


    useEffect(() => {
        fetch('https://16ana.net/ayc/getcategories')
            .then(res => res.json())
            .then(data => {
                setCategories(data);
                setLoadingData(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://16ana.net/ayc/getbrands')
            .then(res => res.json())
            .then(data => {
                setBrands(data);
                setLoadingData(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://16ana.net/ayc/getunits')
            .then(res => res.json())
            .then(data => {
                setUnits(data);
                setLoadingData(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://16ana.net/ayc/getorigins')
            .then(res => res.json())
            .then(data => {
                setOrigins(data);
                setLoadingData(false)
            })
    }, [])

    if (loading) {
        return <Loader />
    }
    if (loadingData) {
        return <Loader />
    }

    const onSubmit = data => {
        const name = data.name;
        const productCode = data.code;
        const category = data.category;
        const brand = data.brand;
        const origin = data.origin;
        const unit = data.unit;
        const purchase = parseFloat(data.purchase);
        const sell = parseFloat(data.sell);
        const minimum = parseFloat(data.minimum);
        const opening = parseFloat(data.opening);
        const user = userAc?.email.split('@')[0]
        const status = data.status;


        function removeSpaces(str) {
            return str.replace(/\s/g, "");
        }
        const code = removeSpaces(productCode);

        const newData = { name, code, category, brand, origin, unit, purchase, sell, minimum, opening, user, status };

        // postProduct(newData);

        

        fetch('https://16ana.net/ayc/products', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newData),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Product Added')
                reset();
                refetchData();
                setIsExpend(false)
            })

    }

    const handleDeleteProduct = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://16ana.net/ayc/getproducts/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateProduct(null);
                    refetchData(pageNo)
                })
        }
    };

    const handleupdateProduct = (product) => {
        setUpdateProduct(product);
    }

    const handleApprove = (product) => {
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://16ana.net/ayc/getproducts/${product._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ approvedBy })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Product Approved');
                refetchData(pageNo)
                // window.location.reload(false)
            })
    }

    const handleProductCode = (event) => {
        const code = event.target.value;
        setPdCode(code)
    }

    // if(isSuccess){
    //     toast.success('Product Addeed')
    // }

    return (
        <div key={key}>
            <div className={`${isExpend ? 'shadow-2xl' : ''} px-12 pb-5 mt-5 text-xs`}>
                <div className='text-right'><button onClick={() => setIsExpend(!isExpend)} className='btn btn-sm btn-success text-white'>{isExpend === true ? <AiOutlineMinus className='text-lg font-bold' /> : <AiOutlinePlus className='text-lg font-bold' />}Add Product</button></div>
                <form onSubmit={handleSubmit(onSubmit)} className={`container grid grid-cols-1 lg:grid-cols-4 gap-1 justify-center items-center ${isExpend === true ? "" : "hidden"}`}>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Product Name</span>
                        </label>
                        <input {...register("name")} type="text" placeholder="Type Product Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Code {duplicate&&<span className='text-teal-600 text-xs font-bold'>({'Duplicate'})</span>}</span>
                        </label>
                        <input {...register("code")} type="text" onChange={handleProductCode} placeholder="Type Product Code Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Product Category</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("category")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option disabled selected>Select Category</option>
                            {
                                categories.map(category => <option>{category.name}</option>)
                            }

                        </select>
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Product Brand</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("brand")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option disabled selected>Select Brand</option>
                            {
                                brands.map(brand => <option>{brand.name}</option>)
                            }
                        </select>
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Product Origin</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("origin")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option disabled selected>Select Origin</option>
                            {
                                origins.map(origin => <option>{origin.name}</option>)
                            }

                        </select>
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Product Unit</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("unit")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option disabled selected>Select Unit</option>
                            {
                                units.map(unit => <option>{unit.type}</option>)
                            }

                        </select>
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Purchase Price</span>
                        </label>
                        <input {...register("purchase")} type="text" placeholder="Purchase Rate" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Sell Price</span>
                        </label>
                        <input {...register("sell")} type="text" placeholder="Sell Price" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Minimum Stock</span>
                        </label>
                        <input {...register("minimum")} type="number" placeholder="Minimum Stock" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Opening Stock</span>
                        </label>
                        <input {...register("opening")} type="number" placeholder="Opening Stock" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Status</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option selected>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div>
                    <input disabled={!userAc?.addProduct| duplicate } type="submit" value='Add Product' className='btn w-full lg:w-full bg-teal-600 text-white lg:mt-9' />
                </form>
            </div>
            <div className='text-xs mx-2 shadow-2xl p-2 lg:p-5'>
                <div className={`${isExpend === false ? "" : "hidden"}`}>
                    <div className='flex justify-between items-center my-4'>
                        <div class="form-control w-28  max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text text-xs">Show</span>
                            </label>
                            <select onChange={handleLimit} defaultValue='' class="text-center rounded-lg text-black select select-bordered select-error w-full text-xs max-w-xs" required>
                                <option selected >20</option>
                                <option>10</option>
                                <option>50</option>
                                <option>100</option>
                                <option>All</option>
                            </select>
                        </div>
                        <div class="form-control w-full mt-9 ml-2 lg:mr-0">
                            <input onChange={handleSearchResult} type="text" placeholder="Search Product" class="input input-bordered bg-white input-error text-xs w-full max-w-xs" />
                        </div>
                    </div>
                    {
                        items?.length ? <div class="overflow-x-auto mt-8">
                            <table class="table w-11/12 mx-auto">
                                <thead className='text-center'>
                                    <tr>
                                        <th className='bg-teal-600 text-white normal-case'>SL</th>
                                        <th className='bg-teal-600 text-white normal-case'>Product Code</th>
                                        <th className='bg-teal-600 text-white normal-case'>Product Name</th>
                                        <th className='bg-teal-600 text-white normal-case'>Category</th>
                                        <th className='bg-teal-600 text-white normal-case'>Brand</th>
                                        <th className='bg-teal-600 text-white normal-case'>Unit</th>
                                        <th className='bg-teal-600 text-white normal-case'>Purchase</th>
                                        <th className='bg-teal-600 text-white normal-case'>Sell</th>
                                        <th className='bg-teal-600 text-white normal-case'>Status</th>
                                        <th className='bg-teal-600 text-white normal-case'>Added</th>
                                        <th className='bg-teal-600 text-white normal-case'>Approved</th>
                                        <th className='bg-teal-600 text-white normal-case'>Edit</th>
                                        <th className='bg-teal-600 text-white normal-case'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {

                                        items?.map((product, index) => <tr className='hover'>
                                            <td>{limit === "All" ? index + 1 : index + 1 + limit * (pageNo - 1)}</td>
                                            <td>{product.code}</td>
                                            <td>{product.name}</td>
                                            <td>{product.category}</td>
                                            <td>{product.brand}</td>
                                            <td>{product.unit}</td>
                                            <td>{product.purchase}</td>
                                            <td>{product.sell}</td>
                                            <td> <button className={`btn btn-xs normal-case ${product.status === 'Active' ? 'btn-success' : 'btn-success'} text-white`}>{product?.status}</button> </td>
                                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{product?.user ? product.user : "User"}</button></td>
                                            {userAc.approvalProduct ? <td>{product?.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{product?.approvedBy}</button> : <button onClick={() => handleApprove(product)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td> :
                                                <td>{product.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{product.approvedBy}</button> : <button className='btn btn-xs btn-success text-white'>Not Approved</button>}</td>}
                                            {userAc?.editProduct && <td><button><label for="update-modal" onClick={() => handleupdateProduct(product)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                            {userAc?.deleteProduct && <td><button onClick={() => handleDeleteProduct(product._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div> : ""
                    }
                    {limit === "All" ? "" : <div className='my-8 text-xs'>
                        <ReactPaginate
                            forcePage={pageNo-1}
                            previousLabel={'Pre'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={'btn-group justify-center'}
                            pageClassName={'btn btn-success text-white'}
                            activeClassName={'btn bg-white text-black'}
                            previousClassName={'btn btn-success text-white'}
                            nextClassName={'btn btn-success text-white'}
                            breakClassName={'btn btn-success text-white'}>
                           
                        </ReactPaginate>
                    </div>}
                </div>
            </div>
            {
                updateProduct && <UpdateModal refetchData={refetchData} pageNo={pageNo} setUpdateProduct={setUpdateProduct} updateProduct={updateProduct}></UpdateModal>
            }
        </div>
    );
};

export default ProductField;