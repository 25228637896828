import { render } from '@testing-library/react';
import { format } from 'date-fns';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearEdit } from '../../../features/InfoSlice/infoSlice';


const UpdateModal = ({ updateCustomer, setUpdateCustomer, refetchData, pageNo }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    // const Supplier = useSelector((state) => state.info.Supplier[0]);
    const { _id, name, code, address, phone, opening, monthly, firstDate, status } = updateCustomer;
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    const onSubmit = (data) => {
        const user = userAc?.email.split('@')[0];
        const approvedBy = false;

        // const inputDateString = data.firstDate;
        const inputDate = new Date(data?.firstDate);
        const firstMonth = inputDate.getFullYear();
        const donarType = data?.monthly === "0" ? 'Regular' : 'Monthly'

        fetch(`https://16ana.net/ayc/getmaincustomer/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ ...data, user, approvedBy, firstMonth, donarType })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setUpdateCustomer(null);
                toast('Customer Upadated Successful');
                refetchData(pageNo);
            })
    }

    const noEdit = () => {
        window.location.reload(false)
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal mt-12">
                <div class="modal-box relative text-xs">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-md font-bold">Update-{name}</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 gap-4 justify-items-center'>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Customer Code</span>
                            </label>
                            <input {...register("code")} type="text" value={code} placeholder="Type Customer Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Customer Name</span>
                            </label>
                            <input {...register("name")} type="text" defaultValue={name} placeholder="Type Customer Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Address</span>
                            </label>
                            <input {...register("address")} type="text" defaultValue={address} placeholder="Type Address Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Phone No</span>
                            </label>
                            <input {...register("phone")} type="text" defaultValue={phone} placeholder="Type Phone No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Monthly Donation Amount</span>
                            </label>
                            <input {...register("monthly")} defaultValue={monthly} type="text" placeholder="Type Monthly Amount" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">First Donation Date</span>
                            </label>
                            {/* <input ref={dateRef} defaultValue={today} type="date" className='rounded-lg bg-gray-200 p-3  text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /> */}
                            <input {...register("firstDate")} defaultValue={firstDate} type="date" placeholder="First Donation Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option >Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Customer' className='btn btn-sm bg-teal-600 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;