import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const UpdateModal = ({ updateBooking, setUpdateBooking }) => {
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    // const { _id, name, date, category, place, link, description} = updateAudio;


    const onSubmit = (data) => {
        const newdate = new Date();
        const date = newdate.toLocaleDateString();
        const time = newdate.toLocaleTimeString();

        fetch(`https://16ana.net/ayc/getallbooking/${updateBooking._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ ...data, date:date, time: time })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setUpdateBooking(null)
                toast('Service Completed')

            })

        const token = `92310756091678413369ac9dc34ff7599524a6ea84a77094beb1`;
        const number = updateBooking.person_phone;
        const msg = `প্রিয় ${updateBooking.person_name}, এনবিসি মটরস এর সার্ভিসে আপনার মেমো নং- ${data.memo}, পার্টস মূল্য- ${data.parts}Tk,${updateBooking.service} চার্জ ${updateBooking.service.includes('Free') ? 0 : data.service_charge}Tk ,ব্যতিক্রম হলে অভিযোগ 01772545974`;
        const message = encodeURI(msg)

        const url = `https://api.greenweb.com.bd/api.php?token=${token}&to=${number}&message=${message}`

        fetch(url)
            .then(res => res.json())
            .then(data => {
                console.log(data);
            })
    }

    return (
        <div className=''>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal">
                <div class="modal-box relative">
                    <label for="update-modal" class="btn btn-sm btn-success text-white font-bolder btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg font-bold">Complete Booking Id : {updateBooking._id.slice(1, 8)}/{updateBooking.person_name}/{updateBooking.service}</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 justify-items-center'>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text">Sales Memo No</span>
                            </label>
                            <input {...register("memo")} type="text" placeholder="Enter Memo No" class="input input-bordered w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text">Pit No</span>
                            </label>
                            <input {...register("pit")} type="text" placeholder="Enter Pit No" class="input input-bordered w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80">
                            <label class="label">
                                <span class="label-text">Select Technician</span>
                            </label>
                            <select  {...register("technician", { required: true })} class="select select-bordered w-full max-w-xs" required>
                                <option>Hira</option>
                                <option>Pintu</option>
                                <option>Khalil</option>
                            </select>
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text">Spare Parts Amount</span>
                            </label>
                            <input {...register("parts")} type="number" placeholder="Spare Parts Amount" class="input input-bordered w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text">Service Charge Amount</span>
                            </label>
                            <input {...register("service_charge")} type="number" placeholder="Type Service Charge Amount" class="input input-bordered w-full max-w-xs" required />
                        </div>
                        <input type='submit' value='Complete Service' className='btn btn-success text-white font-bold mt-4' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;