import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editBrand } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';
import MessageHistory from './MessageHistory';
import useFetchData from '../../../hooks/useFetchData';
import { Modal } from 'antd';



const SendMessage = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [sms, setSms] = useState([]);
    const [length, setLength] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isUnicode, setIsUnicode] = useState();
    const dispatch = useDispatch();
    const [isChecked, setChecked] = useState(false);
    // const { items: customers } = useFetchData('https://16ana.net/ayc/getmaincustomers');
    const [customers, setCustomers] = useState([])
    const countSMS = Math.ceil(isUnicode ? length / 67 : length / 153)
    const [smsStatus, setSmsStatus] = useState({
        sent: 0,
        remaining: customers.length,
    });
    const [modalVisible, setModalVisible] = useState(false);

    const totalSMS = customers?.length * countSMS;



    useEffect(() => {
        fetch('https://16ana.net/ayc/getsms')
            .then(res => res.json())
            .then(data => {
                setSms(data);
                setLoading(false)
            })
    }, [sms, setSms])

    useEffect(() => {
        fetch('https://16ana.net/ayc/getmaincustomers')
            .then(res => res.json())
            .then(data => {
                setCustomers(data);
                setLoading(false)
            })
    }, [sms, setSms])

    if (loading) {
        return <Loader />
    }


    const onSubmit = data => {
        // const user = userAc?.email.split('@')[0]
        // const quantity = parseFloat(data.quantity);
        const token = `92310756091678413369ac9dc34ff7599524a6ea84a77094beb1`;
        const number = data.phone;
        const donorStatus = data.donorPhone;
        const donorPhone = data?.donorPhone?.slice(-11);
        const msg = data.sms
        const message = encodeURI(msg);



        const messageToCustomer = (customer) => {
            const mainNumber = customer ? customer.phone : donorPhone ? donorPhone : number;
            const customerName = customer?.name ? customer.name : 'Unknown';
            const minusAmount = donorStatus === "All" & !isChecked ? totalSMS : countSMS;
            const url = `https://api.greenweb.com.bd/api.php?token=${token}&to=${mainNumber}&message=${message}`
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })

            fetch(`https://16ana.net/ayc/getsms/${sms[0]?._id}`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ smsAmount: sms[0]?.smsAmount - minusAmount }),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    // updateSmsCount(data.smsAmount);
                });

            fetch(`https://16ana.net/ayc/smshistory`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ name: customerName, number: mainNumber, msg })
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    toast.success(`SMS পাঠানো হয়েছে`)
                    reset();
                })
        }

        const sendSMSToAllCustomers = () => {
            setSmsStatus({
                sent: 0,
                remaining: customers.length,
            });
            customers.forEach((customer, index) => {
                // Check if the button is not disabled
                setTimeout(() => {
                    // The button is already disabled if isSmsHistoryExist(customer) is true
                    messageToCustomer(customer);
                    setSmsStatus((prevStatus) => ({
                        sent: prevStatus.sent + 1,
                        remaining: prevStatus.remaining - 1,
                    }));

                }, index * 5000); // 5000 milliseconds (5 seconds) delay for each customer

            });
            setModalVisible(true);
        };

        // messageToSupplier();

        if (donorStatus === "All" & !isChecked) {
            if (sms[0]?.smsAmount > totalSMS) {
                sendSMSToAllCustomers()
            } else {
                toast.error("Not Enough SMS Available")
            }

        } else {
            if (sms[0]?.smsAmount > countSMS) {
                messageToCustomer();
            } else {
                toast.error('"Not Enough SMS Available')
            }

        }




    }



    const handleModalOk = () => {
        setModalVisible(false);
        window.location.reload();
    };

    const handleModalCancel = () => {
        setModalVisible(false);
        window.location.reload();
    };

    function isUnicodeString(str) {
        for (let i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) > 127) {
                return true; // String contains Unicode characters
            }
        }
        return false; // String only contains ASCII characters
    }


    const handleCountLength = (event) => {
        const sms = event.target.value;
        const unicode = isUnicodeString(sms)
        setIsUnicode(unicode)
        setLength(sms.length);
    }


    return (
        <>
            <div className='shadow-md px-2 pb-5 my-5 text-xs'>
                <h1 className='text-lg text-center bg-teal-600 p-3 text-white rounded-xl'>Send Custom SMS</h1>

                <form onSubmit={handleSubmit(onSubmit)} className='container flex flex-col lg:flex-row md:flex-row gap-2 justify-center items-center'>
                    <span className="flex items-center text-lg mt-4">
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={isChecked}
                            onChange={() => setChecked(!isChecked)}
                        />
                        Single
                    </span>
                    {isChecked ? <div class="form-control w-80 max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Phone No</span>
                        </label>
                        <input {...register("phone")} type="text" placeholder="Type Phone Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div> : <div class="form-control w-full lg:w-60 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">Donar</span>
                        </label>
                        <select style={{ padding: '' }} {...register("donorPhone")} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                            <option selected>All</option>
                            {
                                customers.map(customer => <option>{customer.name}-{customer.phone}</option>)
                            }
                        </select>
                    </div>
                    }
                    <div class="form-control w-80 max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Type Message  {length > 0 ? 'Characters: ' + length : ''} <span className='text-red-600'>{length > 0 ? ',SMS: ' + Math.ceil(isUnicode ? length / 67 : length / 153) : ''}</span></span>
                        </label>
                        <textarea {...register("sms")} onChange={handleCountLength} type="text" placeholder="Type SMS Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <input type="submit" value='Send SMS' className='btn bg-teal-600 text-white lg:mt-9' />
                </form>

                {/* {
                    sms.length ? <div class="overflow-x-auto mt-8">
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-teal-600 text-white normal-case'>SL</th>
                                    <th className='bg-teal-600 text-white normal-case'>Available SMS</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    sms.map((s, index) => <tr className='hover'>
                                        <td>{index + 1}</td>
                                        <td>{s.smsAmount}</td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div> : ""
                } */}

            </div>
            <Modal
                title='SMS Status'
                visible={modalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <p>Sent: {smsStatus.sent}</p>
                <p>Remaining: {smsStatus.remaining}</p>
            </Modal>
            {/* <h1 className='text-xl font-bold mt-10'>SMS History</h1> */}
            {/* <MessageHistory /> */}
        </>
    );
};

export default SendMessage;