import React, { useEffect, useState } from 'react';
import CollectionField from './CollectionField';


const AddCollection = () => {
     return (
        <div className='mx-auto p-2 mt-8'>
            <h1 className='text-md text-center bg-teal-600 p-3 text-white rounded-xl'>Add A Donation</h1>
            <CollectionField></CollectionField>
        </div>
    );
};

export default AddCollection;