import React from 'react';


const ViewSalesInvoice = ({ purchaseModal, setPurchaseModal }) => {
    console.log(purchaseModal);
    const {discount,dueAmount,grandTotal,payment,products,salesDate,salesInvoice,salesCustomer,totalAmount,salesAddress,person_name, chargeAmount}=purchaseModal;
    const date= new Date();
    

    const closeModal = () => {
        setPurchaseModal(null);
    }

    return (
        <div>
            <input type="checkbox" id="view-modal" class="modal-toggle" />
            <div class="modal">
                <div class="modal-box max-w-none w-8/12 relative">
                    <label for="view-modal" onClick={closeModal} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-2xl uppercase text-center font-bold">Sales Invoice</h3>
                    <div className='text-left mt-8'>
                        <p><span className='font-bold mr-10'>Invoice No:</span>{salesInvoice}</p>
                        <p><span className='font-bold mr-20'>Date:</span>  {salesDate}</p>
                        <p><span className='font-bold mr-12'>Customer:</span>{person_name}</p>
                    </div>
                    <table class="table w-1/2 mt-8 mx-auto text-sm">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-teal-600 text-white normal-case'>SL#</th>
                                <th className='bg-teal-600 text-white normal-case'>Product Code</th>
                                <th className='bg-teal-600 text-white normal-case'>Product Name</th>
                                <th className='bg-teal-600 text-white normal-case'>Quantity</th>
                                <th className='bg-teal-600 text-white normal-case'>Rate</th>
                                <th className='bg-teal-600 text-white normal-case'>Total</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                products.map((product,index) => <tr className=''>
                                    <td>{index+1}</td>
                                    <td>{product.code}</td>
                                    <td>{product.name}</td>
                                    <td>{product.quantity}</td>
                                    <td>{product.rate}</td>
                                    <td >{product.total}</td>        
                                </tr>)
                            }

                        </tbody>
                        <tbody className='text-center bg-white'>
                            <tr className='border-t-2 border-black'>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td ></td>
                                    <td >Total</td>
                                    <td className='font-bold'>{totalAmount}</td>        
                                </tr>
                            <tr className=''>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>Discount</td>
                                    <td className='font-bold'>{discount}</td>        
                                </tr>
                            <tr className=''>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>Service Charge</td>
                                    <td className='font-bold'>{chargeAmount}</td>        
                                </tr>
                            <tr className=''>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='font-bold'>Grand Amount</td>
                                    <td className='font-bold'>{grandTotal}</td>        
                                </tr>
                            <tr className=''>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>Payment</td>
                                    <td className='font-bold'>{payment}</td>        
                                </tr>
                            <tr className=''>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>Total Due</td>
                                    <td className='font-bold'>{dueAmount}</td>        
                                </tr>
                        </tbody>
                    </table>

                </div>
            </div >
        </div >
    );
};

export default ViewSalesInvoice;